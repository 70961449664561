<template>
  <div>
    <Breadcrumbs :main="$tc('models.serviceProvider.entity', 1)" :title="$tc('models.feedback.entity', 2)"/>
    <b-container>
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12 xl-80">
            <b-card class="m-b-20" no-body v-for="(feedback, id) in records.items" :key="id">
              <b-card-body class="pre">
                <b-row>
                  <b-col cols="3" class="mb-2">
                    <strong>{{ $t('models.feedback.attributes.title') }}</strong>:
                  </b-col>
                  <b-col class="text-center">
                    {{ feedback.title }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="3" class="mb-2">
                    <strong>{{ $t('models.feedback.attributes.details') }}</strong>:
                  </b-col>
                  <b-col class="text-center">
                    {{ feedback.details }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="3" class="mb-2">
                    <strong>{{ $t('models.systemFeedback.attributes.rating') }}</strong>:
                  </b-col>
                  <b-col class="text-center">
                    <span class="col-9 mb-2" v-for="n in 5" :key="n">
                      <i class="fa fa-star"
                        :class="`${feedback.rating >= n ? 'font-warning' : 'font-warning-o'}`">
                      </i>
                    </span>
                  </b-col>
                </b-row>
              </b-card-body>

              <b-card-footer>
                <b-row>
                  <b-col cols="8">
                    <b-row>
                      <b-col cols="3" class="mb-2">
                        <strong>{{ $t('models.feedback.attributes.status') }}</strong>:
                      </b-col>

                      <b-col cols="5">
                        {{ feedback.status.charAt(0).toUpperCase() + feedback.status.slice(1) }}
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <button 
                      :disabled="feedback.status === approved" 
                      class='btn btn-primary btn-block'
                      @click="acceptFeedback(feedback.id)"
                      >
                      <span>{{ $t('actions.accept') }}</span>
                    </button>
                  </b-col>

                  <b-col>
                    <button 
                      :disabled="feedback.status === rejected" 
                      class='btn btn-danger btn-block'
                      @click="rejectFeedback(feedback.id)"
                      >
                      <span>{{ $t('actions.reject') }}</span>
                    </button>
                  </b-col>
                </b-row>
              </b-card-footer>
            </b-card>
            <div class="pagination" v-if="countNotZero">
              <pagination :options="options" :pages="pages" @paginate="paginate"/>
            </div>
            <div v-else>
              {{ $t('messages.error.noContent') }}
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { feedbackStatus } from 'constants';

export default {
  name: 'serviceProviderFeedbacks',
  data() {
    return {
      pages: 0,
      options: {
        page: 1,
        itemsPerPage: 2,
        sortBy: ['createdAt'],
        sortDesc: [true],
        mustSort: ['true'],
        multiSort: false
      },
      approved: feedbackStatus.approved,
      rejected: feedbackStatus.rejected
    };
  },
  methods: {
    ...mapActions({
      getRecords: 'getServiceProviderFeedbacks',
      respond: 'feedbackUpdateStatus'
    }),
    paginate(pageNum) {
      this.options.page = pageNum;
      this.getRecords({ options: this.options, serviceProviderId: this.$route.params.id });
    },
    fetchAndPaginateRecords() {
      this.getRecords( { options: this.options, serviceProviderId: this.$route.params.id } ).then(() => {
        this.pages = this.setPageNumbers(this.records.totalCount, this.options.itemsPerPage);
      });
    },
    acceptFeedback(id) {
      this.respond({ id: id, status: this.approved }).then(() => {
        this.fetchAndPaginateRecords();
      });
    },
    rejectFeedback(id) {
      this.respond({ id: id, status: this.rejected }).then(() => {
        this.fetchAndPaginateRecords();
      });
    }
  },
  computed: {
    ...mapGetters({
      records: 'serviceProviderFeedbacks'
    }),
    countNotZero() {
      return this.records.totalCount > 0;
    }
  },
  created() {
    this.fetchAndPaginateRecords();
  }
};
</script>
